// fancyBox
$('[data-fancybox]').fancybox({
    // Options will go here
    buttons: [
        'slideShow',
        'fullScreen',
        'thumbs',
        'close'
    ]
});

// check mobile nav state
var checkMobileState = $('.mobile-nav').css("display");

// soft scroll function
var softscroll = function (clickelement) {
    clickelement.click(function (event) {
        var indexHref = $(this).attr("href");
        var indexHash = indexHref.split('#')[1];
        var $indexSelector = $("#" + indexHash);

        if ($indexSelector.length > 0) {
            event.preventDefault();
            $('html, body').animate({
                scrollTop: $indexSelector.offset().top - 49
            }, 850);

            // close navigation after selection
            if (checkMobileState == "block") {
                $('.mobile-nav').removeClass("act");
                $('.nav-fixed').fadeOut(300);
            }
        }
    });
}


// softscroll elements (navigation, actionboxes ...)
softscroll($('.softscroll'));

// activate mobile navigation
$('.mobile-nav').on("click", function () {
    var checkState = $(this).hasClass("act");

    if (!checkState) {
        $(this).addClass("act");
        $('.nav-fixed').fadeIn(400);
    } else {
        $(this).removeClass("act");
        $('.nav-fixed').fadeOut(300);
    }
});


// fixed navigation
$(window).on('load', function () {

    // maincontent offset (when should the fixed navigation should be activated)
    if (checkMobileState == "none") {
        var maincontentOffset = $('#maincontent').offset().top - 100;
    } else {
        var maincontentOffset = $('.logo-wrap').offset().top + 20;
    }

    $(window).on('scroll', function () {

        // add fixed class on scroll
        var scrollPos = $(document).scrollTop();

        if (scrollPos > maincontentOffset) {
            $('.header-top').addClass("fixed");
        } else {
            $('.header-top').removeClass("fixed");
        }

        // fade in / fadeout fixed navigation
        if ($('.header-top').hasClass("fixed")) {

            if (checkMobileState == "none") {
                $('.nav-fixed').fadeIn(400);
            } else {
                $('.header-top.fixed').fadeIn(400);
            }

        } else {
            if (checkMobileState == "none") {
                $('.nav-fixed').fadeOut(200);
            } else {
                $('.header-top').removeAttr("style");
            }
        }

        // setting act state for the fixed nav
        var sections = $('.content-part');
        var nav = $('.mainnav');
        var currentPos = $(this).scrollTop();

        sections.each(function () {
            var sectionarea = $(this);
            var top = sectionarea.offset().top - 120;
            var bottom = top + sectionarea.outerHeight();

            if (currentPos >= top && currentPos <= bottom) {
                nav.find('a').removeClass('act');
                sections.removeClass('act');
                $('.content-part').removeClass('act');

                $(this).addClass('act');
                sectionarea.addClass('act');
                nav.find('a[href="#' + $(this).attr('id') + '"]').addClass('act');
            }
            if (currentPos === 0) {
                nav.find('a').removeClass('act');
                sections.removeClass('act');
                $('.content-part').removeClass('act');
            }
        });
    });
});


// equal height for contact boxes

if ($('.contact-box').length) {
    $(window).on('load', function () {

        // equal contactbox height
        var highestBox = 0;

        $('.contact-box').each(function () {
            var boxHeight = $(this).outerHeight();

            if (boxHeight > highestBox) {
                highestBox = boxHeight;
            }
        });

        $('.contact-box').each(function () {
            var box2Height = $(this).height();

            // for all elements which are smaller than the biggest one
            if (box2Height < highestBox) {

                // set the equal height
                $(this).css({
                    "min-height": highestBox,
                })
            }
        });
    });
}

// lazy loading
$('.lazy').Lazy({
    scrollDirection: 'vertical',
    effect: 'fadeIn',
    visibleOnly: true,
    onError: function (element) {
        console.log('error loading ' + element.data('src'));
    }
});

// back to content
$('.scroll-to-content img').click(function () {
    var maincontentPos = $('#maincontent').offset().top;
    $('html, body').animate({
        scrollTop: maincontentPos
    }, 'slow');
    return false;
});


// back to top
$('.back-to-top').click(function () {
    $('html, body').animate({
        scrollTop: 0
    }, 'slow');
    return false;
});


// #highlights - Accordion
$('.mobile-accordion-opener').click(function () {
    $parent_box = jQuery(this).closest('.accordion-content');
    $parent_box.siblings().find('.accoordion-content-inner-wrap').slideUp();
    $parent_box.find('.accoordion-content-inner-wrap').slideToggle(500, 'swing');

    $parent_box.find('.arrow').toggleClass('close-arrow');
    $parent_box.siblings().find('.arrow').removeClass('close-arrow');
});


// #grundrisse  Tabs
$(document).ready(function () {

    // normal tab select
    $('.tabs-secondary .reiter-box .reiter li, .select-wohnung option').click(function () {
        var tab_id = $(this).attr('data-tab');
        let parent = $(this).parent();

        // remove/add color class for tabs
        $('.tabs-secondary .reiter-box .reiter li').removeClass('color');
        $(this).addClass('color');

        // remove/add active class for tabs
        $('.tabs-secondary').addClass('passiv').removeClass('active');
        $(parent).closest('.tabs-secondary').addClass('active').removeClass('passiv');

        $('.tab-content').removeClass('current');

        $(this).addClass('current');
        $("#" + tab_id).addClass('current');
    });
});

// scroll to the top
var scrolltop = $(window).scrollTop();
$(window).scroll(function(){
    var scrollvisibleheight = 1000;
    if($(this).scrollTop() > scrollvisibleheight){
        $('.to-top-arrow-container').fadeIn('slow');
    } else if($(this).scrollTop() < scrollvisibleheight)
    {
        $('.to-top-arrow-container').fadeOut('slow');
    }
});
$('.to-top-arrow-container').click(function(){
    $('html, body').animate({scrollTop:0}, 'slow');
    return false;
});